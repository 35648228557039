@use 'bootstrap' as bs;
html {
  scroll-behavior: smooth;
}

// Flush footer
html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 50px; // Controls spacing from content to footer
}

footer.navbar {
  position: absolute;
  bottom: 0;
  margin-top: 20px;

  background-color: bs.$gray-300;
  color: bs.$navbar-light-color;

  .navbar-text a {
    color: bs.$navbar-light-color;

    &:hover {
      color: bs.$link-color;
    }
  }
}

// End flush footer

body {
  letter-spacing: 0.02rem;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

input[type='search']:focus::-webkit-search-cancel-button {
  display: none;
}

.ais-CurrentRefinements-item {
  .ais-CurrentRefinements-label,
  .ais-CurrentRefinements-category {
    display: block;
    text-align: left;
  }

  .ais-CurrentRefinements-label {
    padding-bottom: 0.5rem;
  }
}

@use 'sass:color';

@use 'app';
@use 'bootstrap' as bs;

body * {
  //border: 1px solid red;
}
.search-result-card {
  min-height: 50px;
  background-color: bs.$gray-100;
  padding: 1rem;
  margin-top: 0.2rem;
}

a.clickable-search-term {
  color: unset;
  text-decoration-line: underline;
  text-decoration-color: bs.$gray-500;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-color: bs.$gray-500;
  cursor: pointer;

  &:hover {
    color: color.adjust(bs.$link-color, $lightness: -50%, $space: hsl);
  }
}

mark {
  color: unset;
  background-color: rgba(bs.$white, 0.9);
  padding: 0 0.08em;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 21px;
  right: 60px;
}

@media (max-width: 1200px) {
  h1,
  .h1 {
    font-size: calc(1.6rem + 1.2vw);
  }
}

.ais-RefinementList-searchBox {
  max-width: 250px;
}
